.popup-container {
  width: 250px;
  margin: 0;
  padding: 0;
  text-align: center;
}

.input-container {
  margin: 5px;
  height: 15px;
  font-size: 14px;
}

.account-popup-container {
  display: flex;
  flex-direction: column;
}

.account-item-popup {
  padding: 2%;
  background: #3498DB;
  cursor: pointer;
  border-radius: 10px;
  margin-bottom: 5%;
  margin-right: 5%;
  margin-left: 5%;
  display: flex;
  flex-direction: row;
}

.account-avatar-popup {
  width: 40px;
  height: 40px;
  flex: 1;
}

.account-data{
  flex: 4;
  align-self: center;
  overflow: clip;
  font-size: 15px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}
