.my-account-container {
  position: relative;
  height: 100%;
}

.logout-container {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
}

.forgot-pwd-btn {
  text-align: center;
  margin-top: 8px;
  font-size: 13px;
  font-family: "Essays 1743", "Linux Libertine", Palatino, "Palatino Linotype", "Book Antiqua", Georgia, "Times New Roman", serif;
  text-decoration: underline;
  cursor: pointer;
}
