.full-height-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.scrollable-content {
  flex-grow: 1;
  overflow-y: auto;
  height: max-content;
}
