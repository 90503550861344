.blog-content {
  //height: 100%;
  overflow-y: auto;
  width: 100%;

  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
}

.blog-content::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
