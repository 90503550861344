html {
  font-size: 62.5%;
}

body {
  font: 300 11px/1.4 'Helvetica Neue', sans-serif;
  color: #f0f0f0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #3498DB;
  overflow: hidden;
  height: 100vh;
  padding: 0;
  margin: 0 0 -4%;
}

h1 {
  font-size: 24px;
  margin-bottom: 20px;
  font-family: Coolvetica, serif;
}

button {
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  background-color: #f0f0f0;
  color: #444444;
  font-family: "Helvetica Neue", serif;
  font-size: 14px;
  font-weight: bold;
  margin: 1px;
}

form {
  display: flex;
  flex-direction: column;
  text-align: left;
}

label {
  margin-right: 2%;
  font-weight: bold;
  font-size: 12px;
}

input {
  padding: 8px;
  margin-bottom: 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 90%;
}

.left-column {
  flex: 1;
  padding: 4% 1% 1%;
  background-color: #f0f0f0;
  color: #000000;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.center-column {
  flex: 6;
  padding: 4% 1% 1%;
  display: flex;
  justify-content: center;
}

.right-column {
  flex: 4;
  padding: 4% 1% 1%;
  background-color: #f0f0f0;
}

@media screen and (max-width: 1200px) {
  body {
    overflow: visible;
  }
}
