ul {
  padding: 0;
  list-style-type: none;
}

.chat-box-container {
  height: 100%;
  display: flex;
  flex-direction: row;
}

.member-list {
  width: 20%;
  height: 99%;
  overflow-y: hidden;
}

.chat-content {
  width: 80%;
  margin-left: 2%;
  margin-right: 2%;
  margin-bottom: 2%;
  padding-top: 5%;
  height: 100%;
}

.chat-messages {
  height: 90%;
  border: 2px solid #444444;
  border-radius: 5px;
  overflow-y: scroll;

  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
}

.chat-messages::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.send-message {
  margin-top: 2%;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.new-message {
  width: 90%;
  border-radius: 5px;
  font-size: 16px;
  min-height: 30px;
  height: fit-content;
  resize: none;
}

.send-button {
  width: 10%;
  border-radius: 10px;
  margin-left: 5px;
  cursor: pointer;
  font-family: Algerian, serif;
  text-align: center;
  padding: 0;
}

.send-button:hover {
  background-color: #2980B9;
  color: #f0f0f0;
}

.chat-box-title {
  color: #444444;
  font-size: 25px;
  font-family: "Essays 1743", "Linux Libertine", Palatino, "Palatino Linotype", "Book Antiqua", Georgia, "Times New Roman", serif;
  text-align: center;
  font-style: italic;
  font-weight: bold;
}

.scrollable-members {
  max-height: 100%;
  overflow-y: scroll;
  //height: 100%;

  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
}

.scrollable-members::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.user-item,
.close-search-user,
.open-search-user {
  padding: 2%;
  background: #444444;
  cursor: pointer;
  box-shadow: 0 8px 8px -4px lightblue;
  border-radius: 10px;
  margin-bottom: 10%;
  display: flex;
  flex-direction: row;
}

.user-avatar-unread-msg {
  width: 40px;
  height: 40px;
  flex: 1;
  border: 2px solid #adff2f;
  border-radius: 50%;
  box-sizing: border-box;
}

.user-avatar {
  width: 40px;
  height: 40px;
  flex: 1;
  border: solid #444444;
  border-radius: 50%;
  box-sizing: border-box;
}

.user-firstName,
.chat-room-name,
.search-name {
  flex: 3;
  align-self: center;
  margin-left: 5%;
  overflow: clip;
  font-size: 15px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.user-item.active {
  background: #3498DB;
  color: #ffffff;
}

.message {
  display: flex;
  flex-direction: row;
  margin-right: 10px;
  margin-left: 10px;
}

.sender {
  display: flex;
  justify-content: flex-end;

  .message-data {
    background-color: #3498DB;
    color: #ffffff;
    border-radius: 10px;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 5px;
    margin-top: 5px;

    word-wrap: break-word; /* Wrap long words */
  }

  pre {
    margin: 0;
    font-size: 13px;
  }
}

.message-author {
  font-size: 10px;
  font-family: 'IBM Plex Sans', sans-serif;
}

.receiver {
  display: flex;
  justify-content: flex-start;

  .message-data {
    background-color: #ffffff;
    color: #444444;
    border-radius: 10px;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 5px;
    margin-top: 5px;

    word-wrap: break-word; /* Wrap long words */
  }

  pre {
    margin: 0;
    font-size: 13px;
  }
}

.hidden {
  display: none;
}

.search-user-container {
  color: black;
  font-size: 10px;
}

.open-search-user {
  background: #f0f0f0;
  color: #000000;
}

.close-search-user {
  background: #E16146;
  color: #f0f0f0;
}

.popup-container {
  width: 400px;
  height: 400px;
  padding: 12px 16px;
  margin: 8px;
  border-radius: 8px;
  border: 2px solid #3498DB;
  background-color: #f0f0f0;
  color: #000000;

  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 15px;
  z-index: 1;
}
