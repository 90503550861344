.scrollable-content {
  overflow-y: auto;
  margin-top: 15px;

  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
}

.scrollable-content::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.myBlog-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.new-post-container {
}

.new-post-title {
  width: 98%;
  font-size: 16px;
}

.new-post-body {
  width: 100%;
  border-radius: 5px;
  font-size: 16px;
  min-height: 100px;
  resize: none;
}
