.account-table {
  border-collapse: collapse;
  width: 100%;

  th, td {
    padding: 0.75rem;
    text-align: center;
    border-bottom: 1px solid #ddd;
    font-size: 15px;
  }

  th {
    font-weight: bold;
  }

  input, select {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 3px;
  }

  input {
    margin-top: 8%;
    font-weight: bolder;
  }

  button {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 3px;
    cursor: pointer;
  }

  .btn-edit {
    background-color: #4caf50;
    color: white;
    margin-right: 5px;
  }

  .btn-remove {
    background-color: #f44336;
    color: white;
  }

  .btn-save {
    background-color: #cccccc;
    color: #444444;
    margin-right: 5px;
  }

  .btn-close {
    background-color: #9e9e9e;
    color: white;
  }

  button:hover {
    opacity: 0.5;
  }
}
