.post {
  font-size: 15px;
  border: 2px solid #f0f0f0;
  margin-bottom: 10px;
  border-radius: 5px;
}

.post-container {

}

.edit-post-container {
  margin: 1%;
  padding: 1%;
}

.edit-post-title {
  font-size: 16px;
  width: 98%;
  padding: 5px;
  margin-bottom: 10px;
}

.edit-post-body {
  border-radius: 5px;
  font-size: 16px;
  min-height: 100px;
  resize: none;

  width: 98%;
  padding: 5px;
}

.post-data-container {
  margin: 5px
}

.post-title {
  font-weight: bold;
  font-size: 22px;
  font-family: "Helvetica Neue", serif;
  color: #f0f0f0;
}

.post-body {
  font-size: 14px;
  color: black;
  margin: 5px;
}

.post-date-and-time {
  display: flex;
  flex-direction: row;
}

.post-created-at {
  flex: 1;
  text-align: left;
}

.post-updated-at {
  flex: 1;
  text-align: right;
}

.post-account-id {
  text-align: center;
  color: #444444;
}

.post-account-data {
  margin-bottom: 10px;
}
