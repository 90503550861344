.account-data {
  text-align: left;
  font-size: 15px;
}

.manage-accounts-btn,
.manage-posts-btn {

}

.logout-container-edit {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
}

.account-container {
  position: relative;
  height: 100%;
}

.remove-account-btn {
  color: #f0f0f0;
  background-color: #ff0000;
}
